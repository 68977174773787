import React, { useState, useEffect, useRef } from 'react'
import Help from '../Help'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { ChromePicker } from 'react-color'

export default function Input(props) {
    const node = useRef()
    const [color, toggleColor] = useState(false)
    const onKeyPress = (event) => {
        const keyCode = event.keyCode || event.which
        const keyValue = String.fromCharCode(keyCode)
        if (/\.|-/.test(keyValue)) event.preventDefault()
    }
    useEffect(() => {
        // add when mounted
        document.addEventListener('mousedown', handleClick)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [])
    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            toggleColor(true)
            return
        }
        // outside click
        toggleColor(false)
    }
    return props.columns === 2 ? (
        <div className={props.sm ? 'form-group-sm' : 'form-group'}>
            <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-4 text-right-lg">
                    {props.label ? (
                        <label className="control-label" htmlFor={props.id}>
                            {props.label}: {props.required ? <span className="danger">*</span> : null}
                        </label>
                    ) : null}
                    {props.help ? <Help id={props.id} help={props.help} /> : null}
                </div>
                <div className="col-xs-12 col-md-12 col-lg-6">
                    <Container className={props.icon ? 'input-icon' : null} ref={node} color={props.color}>
                        {props.iconFirst && props.icon ? (
                            <span className="input-icon-addon">
                                <i className={props.icon}></i>
                            </span>
                        ) : null}
                        {props.formattedVal ? (
                            <NumberFormat
                                {...props}
                                name={props.id}
                                onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                                className={props.className ? props.className + ' form-control' : 'form-control'}
                            />
                        ) : (
                            <input
                                {...props}
                                name={props.id}
                                invalid={props.invalid}
                                valid={props.valid}
                                tick={props.tick}
                                onBlur={props.onBlur}
                                onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                                className={props.className ? props.className + ' form-control' : 'form-control'}
                            />
                        )}
                        {props.iconLast && props.icon ? (
                            <span className="input-icon-addon">
                                <i className={props.icon}></i>
                            </span>
                        ) : null}
                        <div className="color-container">
                            {color && (
                                <ChromePicker
                                    color={props.color}
                                    onChangeComplete={props.onChangeComplete}
                                    onChange={props.onChangeComplete}
                                />
                            )}
                        </div>
                        <div className="color"></div>
                    </Container>
                </div>
            </div>
        </div>
    ) : (
        <div className={props.sm ? 'form-group-sm' : 'form-group'}>
            {props.label ? (
                <label htmlFor={props.id}>
                    {props.label}: {props.required ? <span className="danger">*</span> : null}
                </label>
            ) : null}
            {props.help ? <Help id={props.id} help={props.help} /> : null}
            <Container className={props.icon ? 'input-icon' : null} ref={node} color={props.color}>
                {props.iconFirst && props.icon ? (
                    <span className="input-icon-addon">
                        <i className={props.icon}></i>
                    </span>
                ) : null}
                {props.formattedVal ? (
                    <NumberFormat
                        {...props}
                        name={props.id}
                        onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                        className={props.className ? props.className + ' form-control' : 'form-control'}
                    />
                ) : (
                    <input
                        {...props}
                        name={props.id}
                        invalid={props.invalid}
                        valid={props.valid}
                        tick={props.tick}
                        onBlur={props.onBlur}
                        onKeyPress={props.normalizenumber ? onKeyPress : undefined}
                        className={props.className ? props.className + ' form-control' : 'form-control'}
                    />
                )}
                {props.iconLast && props.icon ? (
                    <span className="input-icon-addon">
                        <i className={props.icon}></i>
                    </span>
                ) : null}
                <div className="color-container">
                    {color && (
                        <ChromePicker
                            color={props.color}
                            onChangeComplete={props.onChangeComplete}
                            onChange={props.onChangeComplete}
                        />
                    )}
                </div>
                <div className="color"></div>
            </Container>
        </div>
    )
}
const Container = styled.div`
    position: relative;
    .color-container {
        position: absolute;
        left: 0;
        top: 40px;
        z-index: 100;
    }
    .color {
        position: absolute;
        width: 36px;
        height: 36px;
        background: ${(props) => props.color};
        right: 1px;
        top: 1px;
        z-index: 200;
        border: 1px solid ${(props) => props.color};
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
`
