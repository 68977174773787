import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'tabler-react'
import { Row, Col, Alert } from 'reactstrap'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import Fade from 'react-reveal'
import Cookies from 'universal-cookie'
import { Input } from '../../components/FormGroups'
// Local import
import { withContext } from '../../context/withContext'
import { stripeKEY } from '../../utils/config'
import { SubscribeAPI, VerifyCoupon } from '../../api/subscriptions'
import Pricing from '../../components/subscriptions/Pricing'
import NumberFormat from 'react-number-format'
// Constants
const stripePromise = loadStripe(stripeKEY)
const cookies = new Cookies()

const tier1 =
    process.env.NODE_ENV === 'development' ? 'price_1Hd1p3IhfvQmiGqmBmj7rpK9' : 'price_1Hho3HIhfvQmiGqmVy3kbiaz'
const tier2 =
    process.env.NODE_ENV === 'development' ? 'price_1Hd1p3IhfvQmiGqmmxXYF1tv' : 'price_1Hho3HIhfvQmiGqmx6FL5STa'
const tier3 =
    process.env.NODE_ENV === 'development' ? 'price_1Hd1p3IhfvQmiGqmJdzBsYmO' : 'price_1Hho3GIhfvQmiGqmQw7cwNfD'

const ActivateAccount = (props) => (
    <Elements stripe={stripePromise}>
        <ActivateForm {...props} />
    </Elements>
)

function ActivateForm(props) {
    const [data, setData] = useState({
        plan: tier2,
        price: 69,
        coupon: '',
    })
    const [loading, setLoader] = useState(false)
    const [alert, showAlert] = useState('')
    const [disabled, enableForm] = useState(true)
    const [activated, setActive] = useState(false)
    const [planSelected, selectedPlan] = useState(false)
    // coupons
    const [couponApplied, applyCoupon] = useState(false)
    const [couponError, applyCouponError] = useState(false)
    const [couponData, setCouponData] = useState({})
    //
    const stripe = useStripe()
    const elements = useElements()
    const date = moment().add(7, 'days').format('ll')
    const token = props.context.token

    const handleActivation = async (e) => {
        e.preventDefault()
        setLoader(true)
        showAlert('')
        if (!stripe || !elements) {
            setLoader(false)
            showAlert('Verify that the credit card is correct.')
            return
        }
        const card = elements.getElement(CardElement)
        const result = await stripe.createToken(card)
        if (result.error) {
            setLoader(false)
            showAlert(result.error.message)
        } else {
            stripeTokenSubscriptionHandler(result.token)
        }
    }

    const stripeTokenSubscriptionHandler = async (stripeToken) => {
        let newData = Object.assign({}, data)
        newData['paymentMethod'] = stripeToken
        try {
            // Suscribimos el usuario via Stripe API
            await SubscribeAPI(token, newData)
            window.scrollTo(0, 0)
            setLoader(false)
            setActive(true)
            // Activamos la compañía en el State
            setTimeout(() => {
                let company_settings = props.context.company
                company_settings.is_active = true
                props.updateContextVal({ company_settings })
            }, 3500)
        } catch (error) {
            // if (error.response.data === 'Este correo ya se encuentra registrado.') setStep(1)
            showAlert('Hmm, it seems like there was an error. Please report it.')
            setLoader(false)
        }
    }

    const handleCardChange = (e) => {
        if (e.complete) {
            enableForm(false)
        } else {
            enableForm(true)
        }
    }

    const SelectPlan = (plan, price) => {
        let newData = Object.assign({}, data)
        newData.plan = plan
        newData.price = price
        setData({ ...newData })
        selectedPlan(true)
    }

    const handleChangeInput = (index, e) => {
        let newData = Object.assign({}, data)
        newData[index] = e.target.value
        setData({ ...newData })
    }

    const CheckCoupon = async (val) => {
        applyCouponError(false)
        applyCoupon(false)
        if (val !== null && val !== '') {
            try {
                let res = await VerifyCoupon(val)
                setCouponData(res.data)
                applyCoupon(true)
            } catch (error) {
                applyCouponError(true)
                setCouponData({})
                console.log(error)
            }
        }
    }

    const handleChangePlan = () => {
        applyCoupon(false)
        applyCouponError(false)
        setCouponData({})
        let newData = Object.assign({}, data)
        newData.coupon = ''
        setData({ ...newData })
        selectedPlan(false)
    }

    const handleLogout = () => {
        props.history.push('/')
        cookies.remove('token')
        cookies.remove('company')
        let state = {
            token: null,
            company: null,
            collapseSidebar: cookies.get('toggle'),
            isLogged: false,
            isActive: false,
            user: {},
            settings: {},
        }
        props.updateContextVal(state)
    }
    return (
        <React.Fragment>
            <div className="logo-container w-100">
                <div className="card-body text-center">
                    <img
                        src={'https://d2p0bx8wfdkjkb.cloudfront.net/static/companies/2020/8/jDDbLMFGKK-formly-logo.png'}
                        height="50px"
                        // style={{ width: '100%', paddingTop: '1rem' }}
                    />
                </div>
            </div>
            <Fade>
                {planSelected ? (
                    <Fade bottom>
                        <Container>
                            <div className="card mt-2">
                                <div className="card-body">
                                    {activated ? (
                                        <React.Fragment>
                                            <div id="title_1" className="text-center">
                                                <img
                                                    src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/kdxq3HEC6d-animat-checkmark-color.gif"
                                                    height="150px"
                                                />
                                                <h1>Welcome Aboard</h1>
                                                <p>
                                                    Your account has been activated and you will be redirected to your
                                                    dashboard in 5 seconds.
                                                </p>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <form onSubmit={handleActivation}>
                                            <div id="title_1" className="text-center">
                                                <h1>Activate Formly Account</h1>
                                                <p>Complete your signup by choosing a plan</p>
                                            </div>
                                            <div className="form-group">
                                                <ul>
                                                    <li className="pb-2">
                                                        <i className="fas fa-check"></i> Free Trial of 7 Days.
                                                    </li>
                                                    <li className="pb-2">
                                                        <i className="fas fa-check"></i> You wont be charged today.
                                                    </li>
                                                    <li className="pb-2">
                                                        <i className="fas fa-check"></i> Cancel anytime before {date}
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <div className="form-group">
                                                <Plans>
                                                    {data.plan === tier1 && (
                                                        <li className={data.plan === tier1 ? 'active' : ''}>
                                                            <span className="title d-block">Basic Plan</span>
                                                            <p className="low-opacity">Max 1 form</p>
                                                            <div className="price_li">$49</div>
                                                        </li>
                                                    )}
                                                    {data.plan === tier2 && (
                                                        <li className={data.plan === tier2 ? 'active' : ''}>
                                                            <span className="title d-block">Pro Plan</span>
                                                            <p className="low-opacity">Max 5 forms</p>
                                                            <div className="price_li">$99</div>
                                                        </li>
                                                    )}
                                                    {data.plan === tier3 && (
                                                        <li className={data.plan === tier3 ? 'active' : ''}>
                                                            <span className="title d-block">Agency Enterprise</span>
                                                            <p className="low-opacity">Formly Unlimited.</p>
                                                            <div className="price_li">$199</div>
                                                        </li>
                                                    )}
                                                </Plans>
                                            </div> */}
                                            <div className="form-group">
                                                <label>
                                                    Card Information: <span className="danger">*</span>
                                                </label>
                                                <fieldset className="FormGroup">
                                                    <div className="FormRow">
                                                        <CardElement onChange={handleCardChange} />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <Input
                                                value={data.coupon}
                                                label="Coupon Code"
                                                onChange={(e) => handleChangeInput('coupon', e)}
                                                onBlur={(e) => CheckCoupon(e.target.value)}
                                            />
                                            {couponError && <span className="danger">This coupon doesn't exists.</span>}
                                            <div className="form-group">
                                                <label>Total:</label>
                                                <div className="table-responsive pb-4">
                                                    <table
                                                        className="bp3-html-table w-100 bp3-html-table-striped bp3-interactive"
                                                        style={{ border: '1px solid #eee' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Description</th>
                                                                <th className="text-right">Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {data.plan === tier1 && 'Basic Plan'}
                                                                    {data.plan === tier2 && 'Advanced Plan'}
                                                                    {data.plan === tier3 && 'Pro Plan'} (
                                                                    <span
                                                                        className="fake-link"
                                                                        onClick={handleChangePlan}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        Change
                                                                    </span>
                                                                    )
                                                                </td>
                                                                <td className="text-right">
                                                                    <NumberFormat
                                                                        displayType="text"
                                                                        value={data.price}
                                                                        thousandSeparator
                                                                        prefix="US$ "
                                                                    />
                                                                </td>
                                                            </tr>
                                                            {couponApplied && (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td>Discount ({data.discount}%)</td>
                                                                        <td className="text-right">
                                                                            <span className="text-green">
                                                                                <NumberFormat
                                                                                    displayType="text"
                                                                                    value={
                                                                                        couponData.redeem_by ===
                                                                                        'percent_off'
                                                                                            ? (data.price *
                                                                                                  couponData.percent_off) /
                                                                                              100
                                                                                            : couponData.amount_off
                                                                                    }
                                                                                    thousandSeparator
                                                                                    prefix="- US$ "
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total</td>
                                                                        <td className="text-right">
                                                                            {couponApplied ? (
                                                                                <NumberFormat
                                                                                    displayType="text"
                                                                                    value={
                                                                                        couponData.redeem_by ===
                                                                                        'percent_off'
                                                                                            ? data.price -
                                                                                              (data.price *
                                                                                                  couponData.percent_off) /
                                                                                                  100
                                                                                            : data.price -
                                                                                              couponData.amount_off
                                                                                    }
                                                                                    thousandSeparator
                                                                                    prefix="US$ "
                                                                                />
                                                                            ) : (
                                                                                <NumberFormat
                                                                                    displayType="text"
                                                                                    value={data.price}
                                                                                    thousandSeparator
                                                                                    prefix="US$ "
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            {alert !== '' && <Alert color="danger">{alert}</Alert>}
                                            <Button
                                                className="w-100"
                                                color="success"
                                                disabled={disabled}
                                                loading={loading}>
                                                Activate
                                            </Button>
                                            <center>
                                                <p className="low-opacity pt-2">
                                                    Once your trial begins, you will have 7 days to use Formly for free.
                                                    After 7 days you will be charged.
                                                </p>
                                            </center>
                                        </form>
                                    )}
                                </div>
                            </div>
                            <div className="text-center text-muted">
                                <span style={{ cursor: 'pointer' }} onClick={handleLogout}>
                                    Logout
                                </span>
                            </div>
                        </Container>
                    </Fade>
                ) : (
                    <React.Fragment>
                        <div className="container">
                            <div className="card mt-4">
                                <div className="card-body">
                                    <center>
                                        <h1>Welcome to Formly</h1>
                                        <p>
                                            We still need to activate your account, select the plan that best suits your
                                            needs.
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <Pricing SelectPlan={SelectPlan} tier1={tier1} tier2={tier2} tier3={tier3} />
                    </React.Fragment>
                )}
            </Fade>
        </React.Fragment>
    )
}
const Container = styled.div`
    max-width: 500px;
    margin: 0 auto;
    .card {
        border-color: #fff;
        border-radius: 3px;
    }
    h1,
    h2 {
        font-size: 2rem !important;
        margin-top: 0px;
        margin-bottom: 32px;
        color: #353e44;
        line-height: 56px;
        font-weight: 700;
        letter-spacing: -0.04em;
        margin-bottom: 0 !important;
    }
    h2 {
        font-size: 1.5rem !important;
    }
    button {
        font-size: 20px;
        font-weight: 700;
    }
    button.btn-success {
        background-color: #007bff !important;
        border-color: #007bff !important;
        :hover {
            opacity: 0.7;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        i {
            color: #007bff;
        }
    }
    .disabled {
        opacity: 0.5 !important;
    }
    .fake-link {
        text-decoration: underline;
        color: #007bff !important;
    }
`

const Plans = styled.ul`
    transition: all 0.3s;
    li {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #eee;
        cursor: pointer;
        transition: all 0.3s;
        margin-bottom: 10px;
        position: relative;
        :hover {
            border-color: #007bff;
        }
        p {
            margin: 0;
            padding: 0;
        }
    }
    li.active {
        border-color: #007bff;
        .title {
            font-weight: 600;
            color: #007bff;
        }
        .price_li {
            color: #007bff;
        }
    }
    .title {
        font-size: 18px;
    }
    .price_li {
        position: absolute;
        color: #d8d8d8;
        right: 8px;
        top: 15px;
        font-family: 'Rubik', sans-serif;
        font-weight: 700;
        font-size: 25px;
    }
`

export default withRouter(withContext(ActivateAccount))
