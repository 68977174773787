import React from 'react'
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export default function Help(props) {
    return (
        <div className="d-inline">
            <ReactTooltip place="top" id={props.id} type="dark" effect="solid">
                {props.help}
            </ReactTooltip>
            <HelpMark data-tip data-for={props.id}>?</HelpMark>
        </div>
    )
}
const HelpMark = styled.span`
    display: inline-block;
    padding: 0;
    width: 1rem;
    height: 1rem;
    font-size: 12px;
    color: #FFF;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #cdd2da;
    border-radius: 50px;
    font-weight: 900!important;
    cursor: help;
    opacity: .5;
    margin-left: .5rem;
`