import axios from 'axios'

const baseURL =
    process.env.NODE_ENV === 'development' ? 'https://secure.form-ly.com/api/v1' : 'https://secure.form-ly.com/api/v1'

export const WebsiteURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'http://form-ly.com/'
export const ServerURL =
    process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/v1/' : 'http://secure.form-ly.com/api/v1/'

export const CLIENT_NAME = 'AlterEstate'
export const MapParams = { v: '1.exp', key: 'AIzaSyBMgeMjcsVGy55pHH6KlZ5Spf4Tu7ZFYFY' }
// Development
export const WEBURL = 'http://localhost:8000/'
export const SERVER_URL = baseURL
export const PHOTO_GALLERY = 'https://secure.form-ly.com/media/'

export const privateAPI = axios.create({
    baseURL: baseURL,
})
export const API = axios.create({
    baseURL: baseURL,
})
export const StripeAPI = axios.create({
    baseURL: 'https://api.stripe.com/v1/',
})

export const stripeKEY =
    process.env.NODE_ENV === 'development'
        ? 'pk_test_51HAOM8IhfvQmiGqmzT3RPdBts0Zu5a3oxaohtdwY21Xiht42gMAjsGN9DtTFS7VdnuqPDY9nzKGUC0OP7bYiGk5s00uy7N4kKs'
        : 'pk_live_51HAOM8IhfvQmiGqm3iyCZuyHgm3DmzgHrHaNt1TrFWtXpMV6TgitXE8KIGvsUoTRL40HX8oEB7uQeg0L9Xhxrh3800Oj6aNeyy'
