import React, { Component } from 'react'
import Cookies from 'universal-cookie'
import { ValidateToken } from '../api/core'
import { ControlFieldTypes } from '../helpers/functions'
const cookies = new Cookies()

const UserContext = React.createContext()

class UserProvider extends Component {
    state = {
        token: cookies.get('token'),
        isLogged: false,
        isActive: false,
        size: 1280,
        user: {
            first_name: 'Frank',
            last_name: 'Jimenez',
        },
        company: {
            is_active: false,
            plan_config: {},
        },
        loadingApp: true,
    }
    updateContextVal = (state) => {
        console.group('En el context ✅')
        console.log(state)
        console.groupEnd()
        this.setState(state)
    }
    componentDidMount = () => {
        let token = cookies.get('token')
        let size = window.innerWidth
        this.setState({ size: size })
        if (token !== undefined) {
            ValidateToken(token)
                .then((response) => {
                    this.setState({
                        isLogged: true,
                        loadingApp: false,
                        isActive: response.data.is_active,
                        user: response.data.user,
                        field_types: ControlFieldTypes(response.data.company.plan_config, response.data.field_types),
                        company: response.data.company,
                    })
                    console.log(response.data.company)
                })
                .catch((err) => {
                    // Manejar la redirección hacia el Login y mostrar una alerta
                    // de que ese usuario que intentó burlar la seguridad no está
                    // activcado o registrado en nuestro sistema.
                    this.setState({
                        isLogged: false,
                        loadingApp: false,
                    })
                })
        } else {
            this.setState({ loadingApp: false, isLogged: false })
        }
    }
    render() {
        return (
            <UserContext.Provider
                value={{
                    state: this.state,
                    updateContextVal: this.updateContextVal,
                }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

// then make a consumer which will surface it
const UserConsumer = UserContext.Consumer

export default UserProvider
export { UserConsumer }
