import React from 'react'
import Help from '../Help'

export default function TextArea(props) {
    return (
        <div className={props.sm ? 'form-group-sm' : 'form-group'}>
            {props.label ? (
                <label htmlFor={props.id}>
                    {props.label}: {props.required ? <span className="danger">*</span> : null}
                </label>
            ) : null}
            {props.help ? <Help id={props.id} help={props.help} /> : null}
            {props.new && <span className="hot">NEW</span>}
            <textarea
                placeholder={props.placeholder}
                type={props.type}
                name={props.id}
                disabled={props.disabled}
                id={props.id}
                className={props.className + ' form-control'}
                required={props.required}
                rows={props.rows}
                style={props.style}
                value={props.value}
                onChange={props.onChange}
            />
        </div>
    )
}
