import React, { useState } from 'react'
import styled from 'styled-components'
import { Alert, Row, Col } from 'reactstrap'
import Fade from 'react-reveal'
import { Button } from 'tabler-react'
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { withContext } from '../../context/withContext'
// Local import
import { Input, Select } from '../../components/FormGroups'
import { RegisterUser } from '../../api/core'
import { API } from '../../utils/config'

const cookies = new Cookies()

function Registro(props) {
    const [data, setData] = useState({
        company_name: '',
        company_phone: '',
        company_url: '',
        invitations: [{ email: '' }, { email: '' }, { email: '' }, { email: '' }, { email: '' }],
    })
    const [step, setStep] = useState(1)
    const [emailField, enableEmailField] = useState(false)
    const [validEmail, setValid] = useState(false)
    const [loading, setLoader] = useState(false)
    const [setup, setupCompany] = useState(false)
    const [alert, showAlert] = useState('')
    const [disabled1, enable1] = useState(true)
    const [disabled2, enable2] = useState(true)
    const [loadingInvitations, setLoaderInvitations] = useState(false)
    const [check, showCheck] = useState(false)

    const handleChange = (e) => {
        let index = e.target.getAttribute('id')
        if (index === 'email') {
            console.log('hola')
            data[index] = e.target.value.toLowerCase().replace(' ', '')
        } else {
            data[index] = e.target.value
        }
        // Enables
        if (
            data.first_name !== undefined &&
            data.last_name !== undefined &&
            data.phone !== undefined &&
            data.company_name !== undefined &&
            data.email !== undefined &&
            data.password !== undefined &&
            data.confirm_password !== undefined
        ) {
            enable1(false)
        }
        if (data.company_name !== undefined && data.company_url !== undefined) {
            enable2(false)
        }
        // console.log(data)
        setData({ ...data })
        if (index === 'email') {
            verifyEmail()
        }
    }

    const verifyEmail = () => {
        let d = {
            action: 'email',
            email: data.email,
        }
        if (validateEmail(data.email)) {
            setValid(true)
        } else {
            setValid(false)
        }
    }

    const handlePersonalData = (e) => {
        e.preventDefault()
        showAlert('')
        setLoader(true)
        if (data.password === data.confirm_password) {
            setStep(2)
            setLoader(false)
        } else {
            showAlert('Las contraseñas no coinciden.')
            setLoader(false)
        }
    }

    const handleCompanyData = (e) => {
        e.preventDefault()
        setLoader(true)
        showAlert('')
        RegisterUser(data)
            .then((response) => {
                setLoader(false)
                handleLastStep()
            })
            .catch((error) => {
                if (error.response.data === 'Este correo ya se encuentra registrado.') setStep(1)
                showAlert(error.response.data)
                setLoader(false)
            })
    }

    const handleLastStep = async (e) => {
        if (e) e.preventDefault()
        showAlert('')
        setLoaderInvitations(true)
        setupCompany(true)
        setTimeout(() => {
            showCheck(true)
        }, 5000)
        setTimeout(() => {
            handleLogin()
        }, 6500)
    }

    const handleLogin = (e) => {
        API.post('/login/', { username: data.email, password: data.password })
            .then((response) => {
                cookies.set('toggle', false, { path: '/' })
                cookies.set('token', response.data.token, { path: '/' })
                let data = {
                    user: response.data.user,
                    field_types: response.data.field_types,
                    token: response.data.token,
                    company: response.data.company,
                    isLogged: true,
                }
                props.updateContextVal(data)
                props.history.push('/')
            })
            .catch((err) => {
                setLoader(true)
                showAlert('Hmm, it seems like there was an error. Please report it.')
            })
    }
    return (
        <Container className="container-fluid">
            <Row>
                <Col xs={12} md={4} className="d-none d-sm-flex no-gutter flex-center">
                    <Side>
                        <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2020/4/FakZtvJ49y-formly-white.png" />
                        <p>Welcome to Formly 👋🏼</p>
                    </Side>
                </Col>
                <Col xs={12} md={8} className="flex-center" style={{ alignItems: 'center' }}>
                    {setup ? (
                        <div style={{ width: '500px' }}>
                            <div className="card">
                                <div className="card-body text-center">
                                    {check ? (
                                        <img
                                            src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/kdxq3HEC6d-animat-checkmark-color.gif"
                                            height="150px"
                                        />
                                    ) : (
                                        <img
                                            src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/HRFudATJ2q-animat-rocket-color.gif"
                                            height="150px"
                                        />
                                    )}
                                    <Fade bottom>
                                        <h4>
                                            {check
                                                ? 'Account created successfully 🎉'
                                                : 'We are setting up your account...'}
                                        </h4>
                                        <p className="mb-4">
                                            Our Oompa Loompa's are creating and setting your account right now, give us
                                            a few seconds to finish the secret formula.
                                        </p>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ maxWidth: '500px' }}>
                            {step === 1 && (
                                <React.Fragment>
                                    <center>
                                        <h4>Create your Account</h4>
                                        <p>Time to set your account up</p>
                                    </center>
                                    <form onSubmit={handlePersonalData} className="card">
                                        <div className="card-body">
                                            <Row>
                                                <Col xs={6}>
                                                    <Input
                                                        required
                                                        type="text"
                                                        label="First name"
                                                        id="first_name"
                                                        value={data.first_name}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <Input
                                                        required
                                                        type="text"
                                                        label="Last name"
                                                        id="last_name"
                                                        value={data.last_name}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <Input
                                                required
                                                type="tel"
                                                label="Phone Number"
                                                id="phone"
                                                placeholder="Ej. 347-652-4012"
                                                value={data.phone}
                                                onChange={handleChange}
                                            />
                                            <Input
                                                required
                                                type="text"
                                                label="Email Address"
                                                className={
                                                    emailField
                                                        ? validEmail
                                                            ? 'form-control is-valid'
                                                            : 'form-control is-invalid'
                                                        : 'form-control'
                                                }
                                                onFocus={() => enableEmailField(true)}
                                                onBlur={verifyEmail}
                                                help="Email that will be used to login on Formly."
                                                id="email"
                                                placeholder="john@doe.com"
                                                value={data.email}
                                                onChange={handleChange}
                                            />
                                            <Input
                                                required
                                                type="password"
                                                label="Password"
                                                id="password"
                                                value={data.password}
                                                onChange={handleChange}
                                            />
                                            <Input
                                                required
                                                type="password"
                                                label="Confirm password"
                                                id="confirm_password"
                                                value={data.confirm_password}
                                                onChange={handleChange}
                                            />
                                            <Row>
                                                <Col xs={12}>
                                                    {alert !== '' && <Alert color="danger">{alert}</Alert>}
                                                    {validEmail ? (
                                                        <Button
                                                            className="w-100"
                                                            color="primary"
                                                            disabled={disabled1}
                                                            loading={loading}>
                                                            Continue
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className="w-100"
                                                            color="primary"
                                                            disabled={true}
                                                            style={{ opacity: 0.6 }}
                                                            loading={loading}>
                                                            Continue
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </form>
                                </React.Fragment>
                            )}
                            {step === 2 && (
                                <React.Fragment>
                                    <center>
                                        <h4>Pleasure to meet you {data.first_name}</h4>
                                        <p>Complete your company profile to create your account.</p>
                                    </center>
                                    <form onSubmit={handleCompanyData} className="card">
                                        <div className="card-body">
                                            <Row>
                                                <Col xs={12}>
                                                    <p>
                                                        Please tell us more about you and your company so we can adjust
                                                        Formly to suit your needs.
                                                    </p>
                                                    <Input
                                                        required
                                                        type="text"
                                                        label="Company name"
                                                        id="company_name"
                                                        value={data.company_name}
                                                        onChange={handleChange}
                                                    />
                                                    <Input
                                                        type="text"
                                                        label="Website"
                                                        id="company_url"
                                                        placeholder="form-ly.com"
                                                        value={data.company_url}
                                                        onChange={handleChange}
                                                    />
                                                    <Input
                                                        required
                                                        type="text"
                                                        label="Office Phone"
                                                        id="company_phone"
                                                        value={data.company_phone}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    {alert !== '' && <Alert color="danger">{alert}</Alert>}
                                                    <Button
                                                        className="w-100"
                                                        color="primary"
                                                        disabled={disabled2}
                                                        loading={loading}>
                                                        Create Account
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </form>
                                </React.Fragment>
                            )}
                            <center>
                                <p>2020 (c) Formly, LLC</p>
                            </center>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
const Container = styled.div`
    position: relative;
    h4 {
        font-weight: 800;
        margin-bottom: 10px;
        font-size: 1.4rem;
    }
    @media only screen and (max-width: 600px) {
        h4 {
            margin-top: 25px;
        }
    }
    .no-gutter {
        padding: 0 !important;
        min-height: 100vh;
        /* background-image: url('https://mir-s3-cdn-cf.behance.net/project_modules/disp/1ce52173426833.5c08f56353039.png'); */
        background-image: linear-gradient(to top, rgba(0, 73, 175, 0.66), #467fcfc7),
            url('https://mir-s3-cdn-cf.behance.net/project_modules/disp/1ce52173426833.5c08f56353039.png');
        img {
            height: 50px;
            margin-bottom: 3rem;
        }
    }
    .laws {
        opacity: 0.6;
    }
    .textHighlight {
        color: #fff;
        font-size: 1.3rem;
        padding: 2rem;
    }
    .author {
        font-weight: 600;
        font-size: 0.9rem;
    }
    .disabled {
        opacity: 0.65 !important;
    }
    button {
        font-size: 20px;
        font-weight: 700;
    }
`
const Side = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    img {
        margin: 0 !important;
        padding: 0;
    }
    p {
        margin-top: 10px;
    }
`
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}
export default withRouter(withContext(Registro))
