import React, { useState } from 'react'
import { Button } from 'tabler-react'
import { Intent } from '@blueprintjs/core'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { withRouter } from 'react-router-dom'
// local import
import { Input } from '../../FormGroups'
import { DeleteClient } from '../../../api/clients'
import { withContext } from '../../../context/withContext'
import { Toast } from '../../Toast'

function DeleteUser(props) {
    const [data, setData] = useState({
        email: '',
    })
    const [loading, setLoader] = useState(false)
    const [unAuthorized, setAuthorized] = useState(true)
    const token = props.context.token

    const handleAuthorize = (e) => {
        let index = e.target.getAttribute('id')
        data[index] = e.target.value
        setData({ ...data })
        if (data.email === props.agent.email) {
            setAuthorized(false)
        }
    }

    const handleSubmit = (e) => {
        setLoader(true)
        DeleteClient(token, props.agent.uid).then((response) => {
            Toast.show({
                message: 'Client successfully deleted from the database.',
                icon: 'tick',
                intent: Intent.SUCCESS,
            })
            props.toggle()
            props.history.push('/clients')
        })
    }
    return (
        <Modal isOpen={props.modal} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>Delete Client</ModalHeader>
            <ModalBody>
                <div>
                    <center>
                        <img src="https://alterestate.s3.amazonaws.com/static/user-1/icons8-warning-shield-48.png" />
                    </center>
                    <p style={{ textAlign: 'justify' }}>
                        You are about to delete all the data related to this client, that includes funnels, submissions,
                        charges, all the records that points to this client will be deleted. Be aware that{' '}
                        <strong className="danger">this is not reversible.</strong>
                        <br />
                        <br />
                    </p>
                    <Input
                        id="email"
                        label="Confirm the email of the client you are about to delete."
                        value={data.email}
                        required={true}
                        onChange={handleAuthorize}
                    />
                    <small>The client email is {props.agent.email}</small>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle}>Cancelar</Button>
                <Button
                    loading={loading}
                    disabled={unAuthorized}
                    onClick={!unAuthorized && handleSubmit}
                    color="danger">
                    Si, eliminar miembro.
                </Button>
            </ModalFooter>
        </Modal>
    )
}
export default withRouter(withContext(DeleteUser))
