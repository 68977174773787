import React, { useState, useEffect } from 'react'
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable'
import Help from '../Help'
import { withContext } from '../../context/withContext'
import { ClientForm } from '../clients/modals'
import { FilterClients } from '../../api/clients'

function SearchableContact(props) {
    const [contacts, setContacts] = useState([])
    const [fullName, setFullname] = useState('')
    const [modal, toggle] = useState(false)
    const [value, setValue] = useState(props.isMulti ? [] : undefined)
    const [debounce, setDebounce] = useState(0)

    useEffect(() => {
        return () => {
            if (props.defaultValue) setValue(props.defaultValue)
        }
    }, [])

    useEffect(() => {
        if (props.defaultValue) setValue(props.defaultValue)
    }, [props.defaultValue])

    const handleChange = (element) => {
        // Aquí hay un forze, revisar después.
        if (props.isMulti) {
            let arr = []
            if (element.length > 0) {
                for (let x in element) {
                    arr.push(element[x].value)
                }
            } else {
                arr.push(element.value)
            }
            setValue(element)
            if (props.onChange) {
                if (props.ReactSelect) {
                    props.onChange(element)
                } else {
                    props.onChange(arr)
                }
            }
        } else {
            setValue(element)
            if (props.onChange && element !== null) {
                if (props.ReactSelect) {
                    props.onChange(element)
                } else {
                    props.onChange(element.value)
                }
            } else {
                props.onChange(null)
            }
        }
    }
    const handleCreate = (e) => {
        toggle(true)
        setFullname(e)
    }
    const loadOptions = (inputValue, callback) => {
        let qs = 'search=' + inputValue
        let arr = []
        let token = props.context.token
        clearTimeout(debounce)
        setDebounce(
            setTimeout(() => {
                return FilterClients(token, qs).then((response) => {
                    setContacts(response.data.results)
                    for (let x = 0; x < response.data.results.length; x++) {
                        let data = response.data.results[x]
                        arr.push({
                            label: `${data.full_name}
                                    ${data.email ? ` - ${data.email}` : ''}`,
                            value: data.uid,
                        })
                    }
                    return callback(arr)
                })
            }, 500)
        )
    }
    return (
        <div className={props.sm ? 'form-group-sm' : 'form-group'}>
            {props.label ? (
                <label htmlFor={props.id}>
                    {props.label}: {props.required ? <span className="danger">*</span> : null}
                </label>
            ) : null}
            {props.help ? <Help id={props.id} help={props.help} /> : null}
            <AsyncCreatableSelect
                cacheOptions
                isClearable
                defaultOptions
                onCreateOption={handleCreate}
                isMulti={props.isMulti}
                value={value}
                loadedOptions={props.defaultValue}
                loadOptions={loadOptions}
                placeholder={props.placeholder ? props.placeholder : 'Select'}
                onChange={handleChange}
                classNamePrefix="react-select"
            />
            {modal && (
                <ClientForm
                    modal={modal}
                    fullName={fullName}
                    captureData={props.captureData}
                    createableChange={handleChange}
                    toggle={() => toggle(false)}
                />
            )}
        </div>
    )
}
export default withContext(SearchableContact)
