import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import ToggleSwitch from './ToggleSwitch'

export default function Switcher(props) {
    return (
        <div>
            <Row className="pb-4" id={props.id}>
                <Col xs={10}>
                    <Label htmlFor={props.id}>
                        {props.title} {props.required && <span className="danger">*</span>}{' '}
                        {props.new && <span className="hot">NEW</span>}
                    </Label>
                    <Small>{props.subtitle}</Small>
                </Col>
                <Col xs={2} className="float-right">
                    <ToggleSwitch
                        required={props.required}
                        id={props.id}
                        value={props.value}
                        className="float-right"
                        onChange={props.onChange}
                    />
                </Col>
            </Row>
        </div>
    )
}
const Label = styled.label`
    font-size: 1rem;
    margin-bottom: 0px;
    display: block;
`
const Small = styled.small`
    font-size: 0.9rem;
    opacity: 0.7;
`
