import React from 'react'
import { Switch, Route } from 'react-router-dom'
// LOCAL IMPORT
import asyncComponent from '../components/asyncComponent'
// Alterestate Version 2
const Dashboard = asyncComponent(() => import('../Pages/dashboard'))
const Forms = asyncComponent(() => import('../Pages/forms'))
const Clients = asyncComponent(() => import('../Pages/clients'))
const Settings = asyncComponent(() => import('../Pages/settings'))
const PageNotFound = asyncComponent(() => import('../Pages/404'))

export default function Content() {
    return (
        <div className="content" id="alterestate-container">
            <Switch>
                <Route path="/" exact component={Dashboard} />
                <Route path="/forms" component={Forms} />
                <Route path="/clients" component={Clients} />
                <Route path="/settings" component={Settings} />
                <Route component={PageNotFound} />
            </Switch>
        </div>
    )
}
