import { API } from '../utils/config'

export function FilterClients(token, qs) {
    return API(`/clients/${qs ? `?${qs}` : ''}`, { headers: { Authorization: `Token ${token}` } })
}
export function SubmitClient(token, data, uid) {
    if (uid) {
        return API.patch(`/clients/${uid}/edit`, data, { headers: { Authorization: `Token ${token}` } })
    } else {
        return API.post(`/clients/new/`, data, { headers: { Authorization: `Token ${token}` } })
    }
}
export function ViewClient(token, uid) {
    return API(`/clients/${uid}/`, { headers: { Authorization: `Token ${token}` } })
}
export function DeleteClient(token, uid) {
    return API.delete(`/clients/${uid}/delete`, { headers: { Authorization: 'Token ' + token } })
}
