import { API } from '../utils/config'

export function FilterUsers(token, qs) {
    return API(`/users/${qs ? `?${qs}` : ''}`, { headers: { Authorization: `Token ${token}` } })
}
export function SubmitUser(token, data, uid) {
    if (uid) {
        return API.patch(`/users/${uid}/edit`, data, { headers: { Authorization: `Token ${token}` } })
    } else {
        return API.post(`/users/new/`, data, { headers: { Authorization: `Token ${token}` } })
    }
}
export function ViewUser(token, uid) {
    return API(`/users/${uid}/`, { headers: { Authorization: `Token ${token}` } })
}
//-------------------------//
//      PERSONALIZATION
//-------------------------//
export function DeleteUser(token, uid) {
    return API.delete(`/users/${uid}/delete`, { headers: { Authorization: 'Token ' + token } })
}
export function UpdatePassword(token, uid, data) {
    return API.patch(`/users/${uid}/edit/password`, data, { headers: { Authorization: 'Token ' + token } })
}
export function ChangeMyPassword(token, data) {
    return API.patch('/users/changeMyPassword', data, { headers: { Authorization: 'Token ' + token } })
}
export function EditUserAvatar(token, uid, data) {
    return API.patch(`/users/${uid}/edit/avatar`, data, {
        headers: { Authorization: 'Token ' + token },
    })
}
export function NotificationsConfigAPI(token, data) {
    if (data) {
        return API.patch(`/notifications/`, data, { headers: { Authorization: `Token ${token}` } })
    } else {
        return API(`/notifications/`, { headers: { Authorization: `Token ${token}` } })
    }
}
export function CompanyConfigAPI(token, data) {
    if (data) {
        return API.patch(`/company/`, data, { headers: { Authorization: `Token ${token}` } })
    } else {
        return API(`/company/`, { headers: { Authorization: `Token ${token}` } })
    }
}
//-------------------------//
//      AUTHENTICATION
//-------------------------//
export function ValidateToken(token) {
    return API.post('/validateToken/', { token: token })
}
export function RegisterUser(data) {
    return API.post('/register/', data)
}
