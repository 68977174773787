// NEW FIELDS SHOULD BE ADDED HERE
export const FieldTypesMaper = {
    text_input: {
        columns: 12,
        field_type: 'text_input',
        label: 'Text Label',
        description: 'Edit me by clicking edit.',
        placeholder: 'Answer here...',
        input_type: 'text',
        required: true,
        options: [],
    },
    full_name: {
        columns: 12,
        field_type: 'full_name',
        label: 'Full Name Field',
        input_type: 'text',
        description: 'Edit me by clicking edit.',
        required: true,
        options: [],
    },
    select: {
        columns: 12,
        field_type: 'select',
        label: 'Select field label',
        description: 'Edit me by clicking edit.',
        required: true,
        options: [],
    },
    text_area: {
        columns: 12,
        field_type: 'text_area',
        label: 'Text area field label',
        description: 'Edit me by clicking edit.',
        placeholder: 'Answer here...',
        required: true,
        options: [],
    },
    choice: {
        columns: 12,
        field_type: 'choice',
        label: 'Choice field label',
        description: 'Edit me by clicking edit.',
        required: true,
        options: [],
    },
    vehicle: {
        columns: 12,
        field_type: 'vehicle',
        label: 'Vehicle',
        description: 'Edit me by clicking edit.',
        required: true,
        options: [],
    },
    insurance_carriers: {
        columns: 12,
        field_type: 'insurance_carriers',
        label: 'Insurance Issuers Label',
        description: 'Edit me by clicking edit.',
        required: false,
        options: [],
    },
    google_maps_input: {
        columns: 12,
        field_type: 'google_maps_input',
        label: 'Google maps api label',
        description: 'Edit me by clicking edit.',
        required: true,
        options: [],
    },
    birthdate: {
        columns: 12,
        field_type: 'birthdate',
        label: 'When is your birthdate?',
        description: 'Edit me by clicking edit.',
        required: true,
        options: [],
    },
    checkbox: {
        columns: 12,
        field_type: 'checkbox',
        label: 'Checkbox Field',
        description: 'You can select more than one option if needed.',
        required: true,
        options: [],
    },
}
