import { FieldTypesMaper } from '../utils/field_type'

export const MoveFunc = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)
    destClone.splice(droppableDestination.index, 0, removed)
    destClone[droppableDestination.index] = FieldTypesMaper[destClone[droppableDestination.index].key]
    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    return result
}
export const ReorderFunc = (list, startIndex, endIndex) => {
    // a little function to help us with reordering the result
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}
export const ControlFieldTypes = (plan_config, fields) => {
    let arr = Object.assign([], fields)
    for (let x = 0; x < fields.length; x++) {
        if (!plan_config.feature_google_address && fields[x].key === 'google_maps_input') {
            arr.splice(x, 1)
        }
    }
    console.log(arr)
    return arr
}
