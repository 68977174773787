import React from 'react'
import { Switch, Route } from 'react-router-dom'
// LOCAL IMPORT
import Login from './login'
import Register from './register'
import ForgotPassword from './forgot-password'
import ForgotPasswordActivate from './forgot-password-activate'

export default function Authentication(props) {
    return (
        <div>
            <Switch>
                <Route path="/register" exact component={Register} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <Route path="/forgot-password-activate" exact component={ForgotPasswordActivate} />
                <Route component={Login} />
            </Switch>
        </div>
    )
}
