import React, { Component } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import 'react-image-lightbox/style.css'
import 'c3/c3.css'
import Principal from './Principal'
import UserProvider, { UserConsumer } from './context/UserProvider'

class App extends Component {
    render() {
        return (
            <UserProvider>
                <UserConsumer>{({ state }) => <Principal user={state} />}</UserConsumer>
            </UserProvider>
        )
    }
}

export default App
