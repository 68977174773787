import React from 'react'
import { BrowserRouter } from 'react-router-dom'
// Local imports
import Authentication from './Pages/auth'
import ActivateAccount from './Pages/auth/activate'
import Content from './Layouts/Content'
import { withContext } from './context/withContext'
import AppLoader from './components/AppLoader'
import Topbar from './Layouts/Topbar'

function Principal(props) {
    const isLogged = props.context.isLogged
    const loadingApp = props.context.loadingApp
    const company = props.context.company

    return (
        <BrowserRouter>
            {loadingApp ? (
                <AppLoader />
            ) : isLogged ? (
                company.is_active ? (
                    <div id="master_container">
                        <div id="topbar">
                            <Topbar />
                        </div>
                        <div id="content">
                            <Content user={props.user} />
                        </div>
                    </div>
                ) : (
                    <ActivateAccount />
                )
            ) : (
                <Authentication />
            )}
        </BrowserRouter>
    )
}

export default withContext(Principal)
