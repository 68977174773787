import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Alert } from 'reactstrap'
import { Button } from 'tabler-react'
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { Spinner } from '@blueprintjs/core'
// Local import
import { withContext } from '../../context/withContext'
import { Input } from '../../components/FormGroups'
import { API } from '../../utils/config'

function ForgotPasswordActivate(props) {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isValid, setValid] = useState(false)
    const [loadingContainer, setLoadingContainer] = useState(true)
    const [alert, showAlert] = useState(false)
    const [loading, showLoading] = useState(false)
    const [completed, setCompleted] = useState(false)

    useEffect(() => {
        let query = queryString.parse(props.history.location.search)
        if (query.token) {
            setValid(true)
            setLoadingContainer(false)
        }
    }, [])

    const ActivateAccount = async (e) => {
        e.preventDefault()
        showAlert(false)
        showLoading(true)
        let query = queryString.parse(props.history.location.search)
        let newData = {
            token: query.token,
            password: password,
        }
        if (password === confirmPassword) {
            try {
                let res = await API.post('/forgot-password/', newData)
                setCompleted(true)
                // setLoader(false)
            } catch (error) {
                console.log(error)
                showAlert(error.response.data)
                showLoading(false)
            }
        } else {
            showLoading(false)
            showAlert('Please check that password match.')
        }
    }
    return (
        <div className="page">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <div className="text-center mb-6">
                                <img
                                    src={
                                        'https://d2p0bx8wfdkjkb.cloudfront.net/static/companies/2020/8/jDDbLMFGKK-formly-logo.png'
                                    }
                                    style={{ width: '100%', paddingTop: '1rem' }}
                                />
                            </div>
                            {loadingContainer ? (
                                <div className="card">
                                    <div className="card-body">
                                        {isValid ? (
                                            <Spinner size={50} />
                                        ) : (
                                            <InvalidContainer>
                                                <i className="d-block fas fa-exclamation-triangle"></i>
                                                <h4>Code Invalid</h4>
                                                <p>
                                                    The code you are requesting expired or is invalid, if you are here
                                                    by error please go back to the login page.
                                                </p>
                                                <Link to="/">
                                                    <Button color="primary" className="btn-block" type="submit">
                                                        Back to Login
                                                    </Button>
                                                </Link>
                                            </InvalidContainer>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <React.Fragment>
                                    {completed ? (
                                        <div className="card">
                                            <div className="card-body p-6">
                                                <center>
                                                    <img
                                                        src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/kdxq3HEC6d-animat-checkmark-color.gif"
                                                        height="150px"
                                                    />
                                                </center>
                                                <div className="card-title">
                                                    <center>Password change successfully</center>
                                                </div>
                                                <p>
                                                    You've changed your password successfully, please go back and login
                                                    to your Formly account and let's start selling.
                                                </p>
                                                <p>El cambio de tu contraseña se ha realizado exitosamente.</p>
                                                <Link to="/">
                                                    <Button color="primary" className="btn-block" type="submit">
                                                        Back to Login
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    ) : (
                                        <form className="card" onSubmit={ActivateAccount}>
                                            <div className="card-body p-6">
                                                <div className="card-title">Restart Password</div>
                                                <p>Put a password that you can remember.</p>
                                                <Input
                                                    required
                                                    type="password"
                                                    label="Password"
                                                    id="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <Input
                                                    required
                                                    type="password"
                                                    label="Confirmar password"
                                                    id="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                <div className="form-footer">
                                                    <Button
                                                        loading={loading}
                                                        color="primary"
                                                        className="btn-block"
                                                        type="submit">
                                                        Reset Password
                                                    </Button>
                                                </div>
                                                {alert && (
                                                    <Alert color="danger" className="mt-4">
                                                        <center>{alert}</center>
                                                    </Alert>
                                                )}
                                            </div>
                                        </form>
                                    )}
                                </React.Fragment>
                            )}

                            <center>Formly (c) - All Rights Reserved.</center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const InvalidContainer = styled.div`
    text-align: center;
    i {
        font-size: 2rem;
        color: orange;
    }
    h4 {
        margin-top: 10px;
        font-size: 1.2rem;
    }
`
export default withRouter(withContext(ForgotPasswordActivate))
