import React from 'react'
import styled from 'styled-components'

function Pricing(props) {
    const { tier1, tier2, tier3 } = props
    const BASIC = [
        { included: true, label: '5 Forms', highlight: false },
        { included: true, label: '500 Entries', highlight: false },
        { included: true, label: 'Unlimited Reporting', highlight: false },
        { included: true, label: 'Email Support', highlight: false },
        { included: true, label: 'Zapier Integration', highlight: false },
        { included: true, label: 'Form Theme Designer', highlight: false },
        { included: false, label: 'Call Now Feature', highlight: false },
        { included: false, label: 'Disqualify Leads', highlight: false },
        { included: false, label: 'Question Logic', highlight: false },
        { included: false, label: 'Custom Form Branding', highlight: false },
        { included: false, label: 'Redirect URL', highlight: false },
        { included: false, label: 'Google Analytics Tracking', highlight: false },
        { included: false, label: 'Facebook Pixel Tracking', highlight: false },
        { included: false, label: 'Google Address API', highlight: false },
        { included: false, label: 'Template Forms', highlight: false },
    ]
    const ADVANCED = [
        { included: true, label: '20 Forms', highlight: false },
        { included: true, label: '5,000 Entries', highlight: false },
        { included: true, label: 'Unlimited Reporting', highlight: false },
        { included: true, label: 'Group + Email Support', highlight: false },
        { included: true, label: 'Zapier Integration', highlight: false },
        { included: true, label: 'Form Theme Designer', highlight: false },
        { included: true, label: 'Call Now Feature', highlight: false },
        { included: true, label: 'Disqualify Leads', highlight: false },
        { included: true, label: 'Question Logic', highlight: false },
        { included: true, label: 'Custom Form Branding', highlight: false },
        { included: true, label: 'Redirect URL', highlight: false },
        { included: true, label: 'Google Analytics Tracking', highlight: false },
        { included: true, label: 'Facebook Pixel Tracking', highlight: false },
        { included: true, label: 'Google Address API', highlight: false },
        { included: true, label: 'Template Forms', highlight: false },
    ]
    const AGENCY = [
        { included: true, label: 'Unlimited Forms', highlight: true },
        { included: true, label: 'Unlimited Entries', highlight: true },
        { included: true, label: 'Unlimited Reporting', highlight: false },
        { included: true, label: 'Group + Email + Priority Support', highlight: false },
        { included: true, label: 'Zapier Integration', highlight: false },
        { included: true, label: 'Form Theme Designer', highlight: false },
        { included: true, label: 'Call Now Feature', highlight: false },
        { included: true, label: 'Disqualify Leads', highlight: false },
        { included: true, label: 'Question Logic', highlight: false },
        { included: true, label: 'Custom Form Branding', highlight: false },
        { included: true, label: 'Redirect URL', highlight: false },
        { included: true, label: 'Google Analytics Tracking', highlight: false },
        { included: true, label: 'Facebook Pixel Tracking', highlight: false },
        { included: true, label: 'Google Address API', highlight: false },
        { included: true, label: 'Template Forms', highlight: false },
    ]
    return (
        <Container>
            <section className="pricing py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card mb-5 mb-lg-0">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">Basic</h5>
                                    <h6 className="card-price text-center">
                                        $29<span className="period">/month</span>
                                    </h6>
                                    <hr />
                                    <ul className="fa-ul">
                                        {BASIC.map((e, key) => (
                                            <li key={key} className={e.included ? '' : 'text-muted'}>
                                                {e.included ? (
                                                    <span className="fa-li">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                ) : (
                                                    <span className="fa-li">
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                )}
                                                {e.highlight ? <strong>{e.label}</strong> : e.label}
                                            </li>
                                        ))}
                                    </ul>
                                    <a
                                        href="#"
                                        className="btn btn-block btn-primary text-uppercase secondaryBtn"
                                        onClick={() => props.SelectPlan(tier1, 29)}>
                                        Select Plan
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card mb-5 mb-lg-0">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">Advanced</h5>
                                    <h6 className="card-price text-center">
                                        $69<span className="period">/month</span>
                                    </h6>
                                    <hr />
                                    <ul className="fa-ul">
                                        {ADVANCED.map((e, key) => (
                                            <li key={key} className={e.included ? '' : 'text-muted'}>
                                                {e.included ? (
                                                    <span className="fa-li">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                ) : (
                                                    <span className="fa-li">
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                )}
                                                {e.highlight ? <strong>{e.label}</strong> : e.label}
                                            </li>
                                        ))}
                                    </ul>
                                    <a
                                        href="#"
                                        className="btn btn-block btn-primary text-uppercase"
                                        onClick={() => props.SelectPlan(tier2, 69)}>
                                        Select Plan
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title text-muted text-uppercase text-center">Agency</h5>
                                    <h6 className="card-price text-center">
                                        $99<span className="period">/month</span>
                                    </h6>
                                    <hr />
                                    <ul className="fa-ul">
                                        {AGENCY.map((e, key) => (
                                            <li key={key} className={e.included ? '' : 'text-muted'}>
                                                {e.included ? (
                                                    <span className="fa-li">
                                                        <i className="fas fa-check"></i>
                                                    </span>
                                                ) : (
                                                    <span className="fa-li">
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                )}
                                                {e.highlight ? <strong>{e.label}</strong> : e.label}
                                            </li>
                                        ))}
                                    </ul>
                                    <a
                                        href="#"
                                        className="btn btn-block btn-primary text-uppercase secondaryBtn"
                                        onClick={() => props.SelectPlan(tier3, 99)}>
                                        Select Plan
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Container>
    )
}
const Container = styled.div`
    .fas.fa-check {
        color: green;
    }
`
export default Pricing
