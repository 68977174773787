import React, { useState } from 'react'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import { Modal, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Spinner, Position, Menu, MenuItem, Popover, Alignment, Navbar, Button } from '@blueprintjs/core'
import { withContext } from '../context/withContext'

const cookies = new Cookies()

function Topbar(props) {
    const user = props.context.user
    const menu = [
        { to: '/', text: 'Dashboard', icon: 'home' },
        { to: '/forms', text: 'Forms', icon: 'folder-open' },
        { to: '/clients', text: 'Clients', icon: 'person' },
        { to: '/settings', text: 'Settings', icon: 'build' },
    ]
    const [modal, toggle] = useState(false)

    const handleLogout = () => {
        toggle(true)
        cookies.remove('token')
        let state = {
            token: null,
            isLogged: false,
            user: {},
        }
        setTimeout(() => {
            toggle(false)
            props.updateContextVal(state)
        }, 2000)
    }
    return (
        <Container>
            <Navbar className="bp3-dark">
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>
                        <strong>
                            <img
                                src="https://d2p0bx8wfdkjkb.cloudfront.net/static/companies/2020/8/3m7J2wTWWs-formly-white.png"
                                height="17px"
                            />
                        </strong>
                    </Navbar.Heading>
                    <Navbar.Divider />
                    {menu.map((e, key) => (
                        <Link to={e.to} key={key}>
                            <Button className="bp3-minimal" icon={e.icon} text={e.text} />
                        </Link>
                    ))}
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Popover
                        content={
                            <PopoverContent updateContextVal={props.updateContextVal} handleLogout={handleLogout} />
                        }
                        position={Position.BOTTOM_RIGHT}
                        target={
                            <Button className="bp3-minimal" icon="user" text={`${user.first_name} ${user.last_name}`} />
                        }
                    />
                </Navbar.Group>
            </Navbar>
            <Modal isOpen={modal}>
                <ModalBody>
                    <div className="card-body text-center">
                        <center>
                            <div className="mt-6 mb-6">
                                <Spinner size={50} />
                                <h3 className="mt-6">Closing session...</h3>
                                <p>See you soon mate!</p>
                            </div>
                        </center>
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    )
}

function PopoverContent(props) {
    return (
        <React.Fragment>
            <Menu>
                <MenuItem icon="log-out" text="Sign out" onClick={props.handleLogout} />
            </Menu>
        </React.Fragment>
    )
}

const Container = styled.div`
    a {
        color: #fff;
        text-decoration: none;
        :hover {
            text-decoration: none;
        }
    }
`
export default withContext(Topbar)
