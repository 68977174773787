import React, { useState } from 'react'
import { Alert } from 'reactstrap'
import { Button } from 'tabler-react'
import { Link, withRouter } from 'react-router-dom'
// Local import
import Logo from '../../static/images/alterestate-full.png'
import { withContext } from '../../context/withContext'
import { Input } from '../../components/FormGroups'
import { API } from '../../utils/config'

function ForgotPassword(props) {
    const [email, setEmail] = useState('')
    const [alert, showAlert] = useState(false)
    const [loading, showLoading] = useState(false)
    const [completed, setCompleted] = useState(false)

    const handleReset = (e) => {
        e.preventDefault()
        showAlert(false)
        showLoading(true)
        API.post('/forgot-password/', { email: email })
            .then((response) => {
                setCompleted(true)
            })
            .catch((err) => {
                showAlert(true)
                showLoading(false)
            })
    }
    return (
        <div className="page">
            <div className="page-single">
                <div className="container">
                    <div className="row">
                        <div className="col col-login mx-auto">
                            <div className="text-center mb-6">
                                <img
                                    src={
                                        'https://d2p0bx8wfdkjkb.cloudfront.net/static/companies/2020/8/jDDbLMFGKK-formly-logo.png'
                                    }
                                    style={{ width: '100%', paddingTop: '1rem' }}
                                />
                            </div>
                            {completed ? (
                                <div className="card">
                                    <div className="card-body p-6">
                                        <center>
                                            <img
                                                src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/kdxq3HEC6d-animat-checkmark-color.gif"
                                                height="150px"
                                            />
                                        </center>
                                        <div className="card-title">
                                            <center>Check your email!</center>
                                        </div>
                                        <p>We've sent you a magic link to reset your password on your account.</p>
                                        <Link to="/">
                                            <Button color="primary" className="btn-block" type="submit">
                                                &larr; Back to Login
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <form className="card" onSubmit={handleReset}>
                                    <div className="card-body p-6">
                                        <div className="card-title">Restart password</div>
                                        <Input
                                            required
                                            type="text"
                                            label="Email"
                                            id="email"
                                            placeholder="Email Address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase().replace(' ', ''))}
                                        />
                                        <div className="form-footer">
                                            <Button
                                                loading={loading}
                                                color="primary"
                                                className="btn-block"
                                                type="submit">
                                                Restart Password
                                            </Button>
                                            <center className="mt-3">
                                                <Link to="/">&larr; Back to Login</Link>
                                            </center>
                                        </div>
                                        {alert && (
                                            <Alert color="danger" className="mt-4">
                                                <center>Check the email and try again!</center>
                                            </Alert>
                                        )}
                                    </div>
                                </form>
                            )}

                            <center>Formly (c) - All Rights Reserved.</center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withRouter(withContext(ForgotPassword))
