import React from 'react';
import { UserConsumer } from './UserProvider';

export const withContext = (Component) => {
    return (props) => (
        <UserConsumer>
            {({ state, updateContextVal }) => (
                <Component {...props} context={state} updateContextVal={updateContextVal} />
            )}
        </UserConsumer>
    );
};