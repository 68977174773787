import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Spinner, Intent } from '@blueprintjs/core'
import { Button, Modal, ModalHeader } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { Input } from '../../FormGroups'
import Switcher from '../../FormGroups/Switcher'
import { withContext } from '../../../context/withContext'
import { ViewClient, SubmitClient } from '../../../api/clients'
import { Toast } from '../../Toast'

function ClientForm(props) {
    const { uid, modal, toggle } = props
    const [data, setData] = useState({})
    const [loading, setLoader] = useState(false)
    const [loadingContainer, setLoaderContainer] = useState(false)
    const token = props.context.token

    useEffect(() => {
        if (uid) RetrieveData(uid) // If uid passed through props means EDIT
    }, [uid])

    const RetrieveData = async (uid) => {
        setLoaderContainer(true)
        try {
            let res = await ViewClient(token, uid)
            setData(res.data)
            setLoaderContainer(false)
        } catch (error) {
            setLoaderContainer(false)
            console.log(error)
        }
    }

    const handleChange = (e) => {
        let index = e.target.getAttribute('id')
        data[index] = e.target.value
        setData({ ...data })
    }

    const handleRadioInput = (e) => {
        let index = e.target.getAttribute('id')
        let value = ''
        if (e.target.value === 'false') {
            value = false
        } else {
            value = true
        }
        data[index] = !value
        setData({ ...data })
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        setLoader(true)
        try {
            let res = await SubmitClient(token, data, uid ? uid : false) // New/Edit endpoint
            console.log(res.data)
            Toast.show({
                message: uid ? 'Client edited successfully' : 'Client created successfully, redirecting...',
                icon: 'tick',
                intent: Intent.SUCCESS,
            })
            setLoader(false)
            if (!uid) props.history.push(`/clients/view/${res.data.uid}`) // if new redirect to client profile
            if (props.RetrieveUser) props.RetrieveUser()
            if (props.toggle) props.toggle()
        } catch (error) {
            console.log(error)
            Toast.show({ message: 'It seems like there was an error creating your client.', intent: Intent.DANGER })
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{uid ? 'Edit' : 'Create'} Client</ModalHeader>
            {loadingContainer ? (
                <LoadingContainer>
                    <Spinner size={50} />
                </LoadingContainer>
            ) : (
                <form onSubmit={SubmitForm}>
                    <div className="modal-body">
                        <Input
                            required={true}
                            label="Full Name"
                            id="full_name"
                            type="text"
                            value={data.full_name}
                            onChange={handleChange}
                        />
                        <Input
                            label="Company Name"
                            id="org_name"
                            type="text"
                            value={data.org_name}
                            onChange={handleChange}
                        />
                        <Input label="Email" id="email" type="email" value={data.email} onChange={handleChange} />
                        <Input label="Phone" id="phone" type="phone" value={data.phone} onChange={handleChange} />
                        <Input label="Phone 2" id="phone_2" type="text" value={data.phone_2} onChange={handleChange} />
                        <Switcher
                            id="is_active"
                            title="Is client active?"
                            subtitle="Just to keep control of all the clients that are active with you."
                            value={data.is_active}
                            onChange={handleRadioInput}
                        />
                    </div>
                    <div className="modal-footer">
                        <a onClick={props.toggle} className="btn btn-secondary">
                            Cancel
                        </a>
                        <div className="spinnerWhite">
                            <Button color="primary" loading={loading}>
                                {loading ? <Spinner size={22} /> : 'Save Client'}
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </Modal>
    )
}
const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 2rem;
`
export default withRouter(withContext(ClientForm))
